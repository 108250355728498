import { Button } from '@pancakeswap/uikit'
import clsx from 'clsx'
import createGlobe from 'cobe'
import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'

const Home: React.FC<React.PropsWithChildren> = () => {
  // const cobeRef = useRef<HTMLCanvasElement>()

  // useEffect(() => {
  //   let phi = 0

  //   const globe = createGlobe(cobeRef.current, {
  //     devicePixelRatio: 2,
  //     width: cobeRef.current.width,
  //     height: cobeRef.current.width,
  //     phi: 0,
  //     theta: 0,
  //     dark: 1,
  //     diffuse: 1.2,
  //     mapSamples: 16000,
  //     mapBrightness: 6,
  //     baseColor: [0.3, 0.3, 0.3],
  //     markerColor: [0.1, 0.8, 1],
  //     glowColor: [1, 1, 1],
  //     markers: [],
  //     onRender: (state) => {
  //       state.width = cobeRef.current.width
  //       state.height = cobeRef.current.width

  //       state.phi = phi
  //       phi += 0.01
  //     },
  //   })

  //   return () => {
  //     globe.destroy()
  //   }
  // }, [])

  return (
    <div className="relative h-screen bg-no-repeat bg-cover font-sans">
      <div className={clsx('lg:pb-0 pb-[50px] text-white text-lg', 'lg:max-w-7xl mx-auto pt-16')}>
        <div className={clsx('flex flex-col justify-between items-center', 'text-center')}>
          <div className="text-4xl md:text-6xl lg:text-8xl leading-none pt-[114px]">THE FIRST STABLECOIN</div>
          <div
            style={{
              background:
                'linear-gradient(90deg, rgba(171, 56, 171, 1) 0%, rgba(227, 107, 93, 1) 29.33%, rgba(255, 160, 0, 1) 66.76%, rgba(255, 234, 0, 1) 100%)',
              WebkitBackgroundClip: 'text',
              color: 'transparent',
              display: 'inline',
              whiteSpace: 'nowrap',
            }}
            className="text-4xl md:text-6xl lg:text-8xl leading-none font-bold min-h-[90px]"
          >
            {' '}
            <Typewriter words={['PROJECT OF FILECOIN']} />{' '}
          </div>
          {/* <div className="text-base lg:text-xl sm:mt-12">
            Offering secure, high-yield investments at low cost and high efficiency. Focused on empowering 
            <br/>the younger generation with wealth growth opportunities in the cryptocurrency world, 
            <br/>it opens new avenues for long-term investment.
          </div> */}
          <div className="flex gap-[27px] mt-[50px] sm:mt-[88px]">
            {/* <Link href='/invitation'>
              <Button variant='text' className="colorful-border flex-col-center !bg-black">Invitation</Button>
            </Link> */}
            {/* !text-[#676767] */}
            <Button variant='text' className="colorful-border flex-col-center !bg-black">
              BUY
            </Button>
            <Button variant='text' className="colorful-border flex-col-center !bg-black">
              STAKING
            </Button>
          </div>
        </div>
      </div>
      <img src="/images/filo/home.jpg" className="w-full h-auto mt-8 md:mt-16 lg:mt-24" alt="" />

      {/* <div className="absolute top-0 left-0 w-full h-screen overflow-hidden pointer-events-none">
        <canvas
          className={clsx(
            'absolute aspect-square left-1/2 -translate-x-1/2',
            'w-[300%] bottom-0 translate-y-[60%]',
            'sm:w-full sm:translate-y-2/3',
          )}
          style={{ zIndex: -1 }}
          ref={cobeRef}
          id="cobe"
        />
      </div> */}
    </div>
  )
}

interface TypewriterProps {
  words: string[]
}

const Typewriter: React.FC<TypewriterProps> = ({ words }) => {
  const [index, setIndex] = useState(0)
  const [subIndex, setSubIndex] = useState(0)
  const [blink, setBlink] = useState(true)

  useEffect(() => {
    const blinkInterval = setInterval(() => {
      setBlink((prev) => !prev)
    }, 500)

    return () => clearInterval(blinkInterval)
  }, [])

  useEffect(() => {
    if (index === words.length) {
      setTimeout(() => {
        setIndex(0)
        setSubIndex(0)
      }, 2000)
      return
    }

    if (subIndex === words[index].length + 1) {
      setTimeout(() => {
        if (index === words.length - 1) {
          setIndex(0)
        } else {
          setIndex((prev) => prev + 1)
        }
        setSubIndex(0)
      }, 2000)
      return
    }

    const timeout = setTimeout(() => {
      setSubIndex((prev) => prev + 1)
    }, 150)

    return () => clearTimeout(timeout)
  }, [subIndex, index, words])

  return (
    <p>
      {words[index]?.substring(0, subIndex)}
      &nbsp;
      <span className={clsx(blink ? 'visible' : 'invisible')}>|</span>
    </p>
  )
}

export default Home
